import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Typography,
  TextField
} from '@mui/material';
import { Document, Packer, Paragraph, Table, TableCell, TableRow } from 'docx';
import { saveAs } from 'file-saver';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../assets/css/pages/AutoFillPage.module.css';
import FileViewer from '../components/FileViewer';
import Header from '../components/Header';
import MarkdownText from '../components/MarkdownText';
import TemplatePopup from '../components/TemplatePopup';
import CONSTANTS from '../config/CONSTANTS';
import { fetchFileUrl } from '../utils/ViewFileHelper';
import { TempFillTemplates } from '../utils/SuggestionTemplates';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const FileViewerMemoized = React.memo(FileViewer);

export function fuzzyMatch(target, content) {
  // Remove all non-alphabetic characters from target
  const sanitizedTarget = target.replace(/[^a-zA-Z]/g, "");

  // Create a RegExp pattern that ignores spaces and other non-alphabetic characters in content
  const pattern = new RegExp(sanitizedTarget.split("").join("[^a-zA-Z]*"), "i");

  // Perform the match
  const match = String(content).match(pattern);

  return match ? match[0] : null; // use the result in your CMD + F
}

function AutoFillPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const file = location.state?.file;
  const [fileURL, setFileURL] = useState('');
  const [options, setOptions] = useState(['Option 1', 'Option 2', 'Customize']); // Options for the select
  const [openPopup, setOpenPopup] = useState(false);
  const templates = TempFillTemplates;
  const [selectedTemplate, setSelectedTemplate] = useState(''); // To store the selected template
  const [templatePrompts, setTemplatePrompts] = useState([]);
  const [templateResponses, setTemplateResponses] = useState([]);
  const [templateSources, setTemplateSources] = useState([]);
  const [templateDetails, setTemplateDetails] = useState(null);
  const [isResultLoading, setResultIsLoading] = useState(false);
  const [templateHistory, setTemplateHistory] = useState([]);
  const [chatInputs, setChatInputs] = useState({});
  const [reanalysisResults, setReanalysisResults] = useState({});
  const [isReanalyzing, setIsReanalyzing] = useState({});
  const [showChat, setShowChat] = useState({});

  var [content, setContent] = useState('');

  // Fetch file content on page load
  useEffect(() => {
    if (!file) {
      console.log('File is missing from the state, navigating back.');
      navigate('/');
      return;
    }
    // console.log(file);

    const loadFile = async () => {
      file.fileName = file.fileName.replace(/\.\w+$/, ".pdf");
      const result = await fetchFileUrl(file);
      if (result.error) {
        console.error(result.error);
      } else {
        setFileURL(result);
      }
    };

    loadFile();
  }, [file, navigate, selectedTemplate]);

  const handleChatSubmit = async (index, additionalContext) => {
    setIsReanalyzing({ ...isReanalyzing, [index]: true });
    try {
      const accessToken = localStorage.getItem('accessToken');
      const userId = localStorage.getItem('userId');
      const tempPrompt = selectedTemplate.real_prompts[index];
      const input = {
        query: tempPrompt,
        uid: userId,
        docId: file.docId,
        isSourceNeeded: false,
        additionalContext: additionalContext,
      };
      const response = await fetch(CONSTANTS['endpoint_get_preset_task'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify(input),
      });
      let reanalysisResult = await response.text();
      let fuzzyMatchedSources = [];

      reanalysisResult = reanalysisResult.replace(/\{"docs":\s*\[\s*\].*\}/, '').trim();
      if (reanalysisResult.startsWith("```json")) {
        reanalysisResult = reanalysisResult.trim().slice(7, -3).trim();
      }
      if (reanalysisResult.startsWith('{')) {
        const parsedMessage = JSON.parse(reanalysisResult);
        if (parsedMessage.response) {
          reanalysisResult = parsedMessage.response;
        }
        if (parsedMessage.sources) {
          // Fuzzy match sources
          fuzzyMatchedSources = parsedMessage.sources.map(source => {
            const fuzzyMatchResult = fuzzyMatch(source, content);
            return fuzzyMatchResult;
          }).filter(result => result !== null);
        }
      }
      if (reanalysisResult === null || typeof (reanalysisResult) != "string" || reanalysisResult.toLowerCase().trim() === 'none') {
        reanalysisResult = selectedTemplate.default_answers[index]; // Fallback to default answer
      } else {
        reanalysisResult = reanalysisResult.trim();
      }

      reanalysisResults[index] = { response: reanalysisResult, sources: fuzzyMatchedSources, additionalContext: additionalContext };
      saveAutoFillHistory(templateResponses, templateSources, reanalysisResults);
    } catch (error) {
      console.error('Error re-analyzing preset task:', error);
    } finally {
      setIsReanalyzing({ ...isReanalyzing, [index]: false });
    }
  };

  const saveAutoFillHistory = (responses, sources, reanalysisResults) => {
    if (!selectedTemplate || !file || responses.length <= 0)
      return;
    try {
      const updatedHistory = [...templateHistory]; // Create a copy to avoid direct mutation
      const existingIndex = updatedHistory.findIndex(item => item.selectedTemplate.brief === selectedTemplate.brief);

      if (existingIndex !== -1) {
        // Update existing template data
        updatedHistory[existingIndex].responses = responses;
        updatedHistory[existingIndex].sources = sources;
        updatedHistory[existingIndex].reanalysisResults = reanalysisResults;
      } else {
        // Add new template data
        updatedHistory.push({
          selectedTemplate: selectedTemplate,
          responses: responses,
          sources: sources,
          reanalysisResults: reanalysisResults,
        });
      }
      setTemplateHistory(updatedHistory);

      const response = fetch(CONSTANTS['endpoint_save_autofill'], {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ` + localStorage.getItem('accessToken'),
        },
        body: JSON.stringify({
          docId: file.docId,
          uid: localStorage.getItem('userId'),
          templates: updatedHistory, // Send the updated history
        }),
      });
      return response;
    } catch (error) {
      console.error('Error saving autofill history:', error);
    }
  };


  const loadAutoFillHistory = async (selectedTemplateValue) => {
    try {
      const response = await fetch(CONSTANTS['endpoint_fetch_autofill'] + `?docId=${file.docId}&uid=${localStorage.getItem('userId')}`);
      if (response.ok) {
        const history = await response.json();
        // console.log('[INFO] History: ');
        // console.log(history);
        if (history && history.templates) {
          setTemplateHistory(history.templates);
          //Check for existing data and update states
          const existingData = history.templates.find(item => item.selectedTemplate.brief === selectedTemplateValue.brief);
          if (existingData) {
            console.log('Restoring autofill template from history');
            setTemplatePrompts(existingData.selectedTemplate.prompts);
            setTemplateResponses(existingData.responses);
            setTemplateSources(existingData.sources);
            setReanalysisResults(existingData.reanalysisResults || {});
          }
        }
      }
    } catch (error) {
      console.error("Error loading autofill history:", error);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedTemplate(value);
    setTemplateDetails(value !== 'customize' ? value : null);
    setTemplatePrompts([]); // Clear previous prompts
    setTemplateResponses([]); // Clear previous responses
    if (value === 'customize') {
      setOpenPopup(true);
    } else {
      loadAutoFillHistory(value);
    }
  };

  const handleClosePopup = (newAdded) => {
    setOpenPopup(false);
    if (!newAdded) {
      setSelectedTemplate('');
      // setSelectedOption('');
    }
  };

  const handleStartButtonClick = () => {
    if (!selectedTemplate || selectedTemplate === 'customize') {
      alert('Please select a valid template or customize a new one.');
      return;
    }
    setResultIsLoading(true);
    // console.log('Selected Template:', selectedTemplate);
    askTempPrompts(selectedTemplate)
      .then(() => {
        console.log('Template processing completed.');
      })
      .catch((error) => {
        console.error('Failed to process template:', error);
      })
      .finally(() => {
        setResultIsLoading(false);
      });
  };

  const handleSave = (title) => {
    if (title) {
      const newOptions = [...options];
      newOptions.splice(options.length - 1, 0, title);
      setOptions(newOptions);
      // setSelectedOption(title);
      handleClosePopup(true);
    } else {
      handleClosePopup(false);
    }
  };

  const handleDownloadDocx = async () => {
    const templateQuestions = templatePrompts;
    const templateAnswers = templateResponses;
    const reanalysisResultsData = reanalysisResults;

    let tableData;
    let headers;

    if (Object.keys(reanalysisResultsData).length > 0) {
      tableData = templateQuestions.map((question, index) => {
        const answer = templateAnswers[index] || '';
        const reanalysisQuery = reanalysisResultsData[index]?.additionalContext || ''
        const reanalysisResult = reanalysisResultsData[index]?.response || '';
        return [question, answer, reanalysisQuery, reanalysisResult];
      });
      headers = ['Question', 'Answer', 'Additional context for re-analysis', 'Re-analysis Result'];
    } else {
      tableData = templateQuestions.map((question, index) => {
        const answer = templateAnswers[index] || '';
        return [question, answer];
      });
      headers = ['Question', 'Answer'];
    }

    // Add header row
    tableData.unshift(headers);

    try {
      const docxBlob = await generateDocx(tableData, headers.length);
      saveAs(docxBlob, 'template_answers.docx');
    } catch (error) {
      console.error('Error generating document:', error);
      alert('Failed to generate document.');
    }
  };

  const generateDocx = (tableData, numColumns) => {
    const doc = new Document({
      sections: [
        {
          children: [createTable(tableData, numColumns)],
        },
      ],
    });

    return Packer.toBlob(doc);
  };

  const createTable = (data, numColumns) => {
    const tableRows = data.map(
      (row) =>
        new TableRow({
          children: row.map(
            (cell) =>
              new TableCell({
                children: [new Paragraph(cell)],
              }),
          ),
        }),
    );

    let columnWidths = [];
    if (numColumns === 4) {
      columnWidths = [2250, 2250, 2250, 2250];
    } else {
      columnWidths = [4500, 4500];
    }

    return new Table({
      columnWidths: columnWidths,
      rows: tableRows,
    });
  };

  const askTempPrompts = async (selectedTemplate) => {
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('userId');
    const { prompts, real_prompts, default_answers } = selectedTemplate;
    let responses = new Array(real_prompts.length).fill('');
    let sources = new Array(real_prompts.length).fill([]);
    try {
      for (let i = 0; i < real_prompts.length; i++) {
        const tempPrompt = real_prompts[i];
        const defaultAnswer = default_answers[i];
        // console.log(i + ': ' + tempPrompt);
        const input = {
          query: tempPrompt,
          uid: userId,
          docId: file.docId,
          isSourceNeeded: false,
        };
        const fetchTextOptions = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
            // 'Access-Control-Allow-Origin': '*',
          },
          body: JSON.stringify(input),
        };
        const response = await fetch(
          CONSTANTS['endpoint_get_preset_task'],
          fetchTextOptions,
        );
        // const wait = 4000;
        // const response = await new Promise((resolve) =>
        //   setTimeout(async () => {
        //     const res = await fetch(
        //       CONSTANTS['endpoint_get_preset_task'],
        //       fetchTextOptions,
        //     );
        //     resolve(res);
        //   }, wait),
        // );

        let completeMessage = await response.text();
        var fuzzyMatchedSources = [];
        completeMessage = completeMessage.replace(/\{"docs":\s*\[\s*\].*\}/, '').trim();
        if (completeMessage.startsWith("```json")) {
          completeMessage = completeMessage.trim().slice(7, -3).trim();
        }
        if (completeMessage.startsWith('{')) {
          const parsedMessage = JSON.parse(completeMessage);
          if (parsedMessage.response) {
            completeMessage = parsedMessage.response;
          }
          if (parsedMessage.sources) {
            // Filter sources using fuzzy match and store the matched strings
            const pageContents = content;
            fuzzyMatchedSources = parsedMessage.sources
              .map(source => {
                const fuzzyMatchResult = fuzzyMatch(source, pageContents);
                return fuzzyMatchResult;
              })
              .filter(result => result !== null);
          }
        }
        if (completeMessage === null || typeof (completeMessage) != "string" || completeMessage.toLowerCase().trim() === 'none') {
          responses[i] = defaultAnswer;
        } else {
          responses[i] = completeMessage.trim();
        }
        sources[i] = fuzzyMatchedSources;
      }
    } catch (error) {
      console.error('Processing error:', error);
    } finally {
      setTemplatePrompts(prompts);
      setTemplateResponses(responses);
      setTemplateSources(sources);
      const response = await saveAutoFillHistory(responses, sources, {});
      if (!response.ok) {
        console.error('Failed to save autofill history:', response.status);
      } else {
        console.log('Saved autofill responses');
      }
    }
  };

  const fileViewerProps = useMemo(
    () => ({
      selectedFileName: file?.fileName,
      fileURL: fileURL,
    }),
    [file?.fileName, fileURL],
  );


  const searchPluginInstance = searchPlugin();

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin(
    {
      sidebarTabs: (defaultTabs) => {
        return [defaultTabs[0]];
      },
      renderToolbar,
    }
  );
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <Box className={styles.pageContainer}>
      <Header pageTitle="Auto-fill Report" showBackHome={true} />
      <Box className={styles.mainContent}>
        <Box className={`${styles.panel} ${styles.leftPanel}`}>
          {fileURL && (file.fileName.endsWith('.pdf') ? (
            <Box style={{ height: '92vh' }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <Viewer
                  fileUrl={fileURL}
                  httpHeaders={{
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                  }}
                  plugins={[searchPluginInstance, defaultLayoutPluginInstance]}
                  onDocumentLoad={async (e) => {
                    // console.log('File URL:', fileURL);
                    const pageNumbers = [...Array(e.doc.numPages + 1).keys()].slice(1);
                    const pageContentPromises = pageNumbers.map(
                      async (pageNumber) => {
                        const page = await e.doc.getPage(pageNumber);
                        return page.getTextContent();
                      }
                    );

                    const pageContents = await Promise.all(pageContentPromises);
                    const contents = pageContents.map((content) => content.items.map((item) => item.str).join('')).join('');
                    setContent(contents);
                    content = contents;
                  }}
                />
              </Worker>
            </Box>
          ) : (
            <FileViewerMemoized {...fileViewerProps} />
          ))}
        </Box>
        <Box className={`${styles.panel} ${styles.rightPanel}`}>
          <Box className={styles.controlGroup}>
            <Box className={styles.selectField}>
              <Select
                value={selectedTemplate || ''}
                onChange={handleChange}
                className={styles.select}
              >
                {templates.map((template, index) => (
                  <MenuItem key={index} value={template}>
                    {template.brief}
                  </MenuItem>
                ))}
                <MenuItem key="customize" value="customize">
                  Customize
                </MenuItem>
              </Select>
              <Button
                variant="contained"
                color="primary"
                onClick={handleStartButtonClick}
                className={styles.button}
              >
                Start
              </Button>
            </Box>
            <Box className={styles.downloadField}>
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                disabled={!(templateResponses.length > 0)}
                onClick={() =>
                  handleDownloadDocx(templateResponses, templatePrompts)
                }
              >
                Download
              </Button>
            </Box>
          </Box>
          <Box className={styles.templateDisplay}>
            {isResultLoading ? (
              <div className={styles.centered}>
                <CircularProgress color="primary" />
              </div>
            ) : templateResponses.length > 0 ? (
              <div className={styles.tableContainer}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Question</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {templatePrompts.map((prompt, index) => (
                      <tr key={index}>
                        <td>{prompt}</td>
                        <td>
                          <Box>
                            <MarkdownText text={templateResponses[index]} />
                            {templateSources[index] && (
                              <Box className={styles.sourcesContainer}>
                                {templateSources[index].map((source, index) => (
                                  <span key={index} style={{ margin: '2px' }}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      className={styles.sourceButton}
                                      onClick={() => {
                                        // console.log('Source String:', source);
                                        searchPluginInstance.highlight([source]);
                                      }}
                                    >
                                      Source {index + 1}
                                    </Button></span>
                                ))}
                                <span><Button variant='outlined' style={{ margin: '2px' }} onClick={() => {
                                  setShowChat({ ...showChat, [index]: !showChat[index] });
                                }}>
                                  {showChat[index] ? 'Hide Chat' : 'Chat'}
                                </Button></span>
                              </Box>
                            )}

                            {showChat[index] && (
                              <div>
                                <TextField
                                  value={chatInputs[index] || ''}
                                  onChange={(e) => setChatInputs({ ...chatInputs, [index]: e.target.value })}
                                  label="Additional Context"
                                  variant="outlined"
                                  fullWidth
                                  margin="dense"
                                />
                                <Button variant='contained' onClick={() => handleChatSubmit(index, chatInputs[index])} disabled={isReanalyzing[index]}>
                                  {isReanalyzing[index] ? 'Re-analyzing...' : 'Submit'}
                                </Button>
                              </div>
                            )}
                            {reanalysisResults[index] && (
                              <div>
                                <Typography><br /><b>Re-analysis Result:</b></Typography>
                                <MarkdownText text={"**Additional context:** " + reanalysisResults[index].additionalContext} />
                                <MarkdownText text={"**Response:** " + reanalysisResults[index].response} />
                                {reanalysisResults[index].sources && (
                                  <Box className={styles.sourcesContainer}>
                                    {reanalysisResults[index].sources.map((source, index) => (
                                      <span key={index} style={{ margin: '2px' }}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          className={styles.sourceButton}
                                          onClick={() => {
                                            // console.log('Source String:', source);
                                            searchPluginInstance.highlight([source]);
                                          }}
                                        >
                                          Source {index + 1}
                                        </Button>
                                      </span>
                                    ))}
                                  </Box>
                                )}
                              </div>
                            )}
                          </Box>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : templateDetails ? (
              <Box className={styles.templateDetails}>
                <Typography variant="h6">
                  {templateDetails.description}
                </Typography>
                <ol>
                  {templateDetails.prompts?.map((question, index) => (
                    <li key={index}>{question}</li>
                  ))}
                </ol>
              </Box>
            ) : (
              <div className={styles.centered}>
                Please select an option from the dropdown to see results.
              </div>
            )}
          </Box>
        </Box>
      </Box>
      <TemplatePopup
        open={openPopup}
        handleClose={() => handleClosePopup(false)}
        handleSave={handleSave}
      />
    </Box>
  );
}

export default AutoFillPage;
