import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../assets/css/pages/ChatPage.module.css';
import FileViewer from '../components/FileViewer';
import Header from '../components/Header';
import MarkdownText from '../components/MarkdownText';
import PromptSelection from '../components/PromptSelection';
import CONSTANTS from '../config/CONSTANTS';
import modePrompts from '../utils/modePrompts';
import { fetchFileUrl } from '../utils/ViewFileHelper';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { templates } from '../utils/SuggestionTemplates';

const FileViewerMemoized = React.memo(FileViewer);

export function fuzzyMatch(target, content) {
  // Remove all non-alphabetic characters from target
  const sanitizedTarget = target.replace(/[^a-zA-Z]/g, "");

  // Create a RegExp pattern that ignores spaces and other non-alphabetic characters in content
  const pattern = new RegExp(sanitizedTarget.split("").join("[^a-zA-Z]*"), "i");

  // Perform the match
  const match = String(content).match(pattern);

  return match ? match[0] : null; // use the result in your CMD + F
}

function ChatPage() {
  const location = useLocation();
  const file = location.state?.file;
  const [messages, setMessages] = useState([]);
  const hasMessages = messages.length > 0;
  var [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messageListRef = useRef(null);
  const navigate = useNavigate();
  const [leftWidth, setLeftWidth] = useState(55); // Initialize left area width percentage
  const minLeftWidth = 30; // Minimum width percentage for the left area
  const minRightWidth = 30; // Minimum width percentage for the right area
  var [content, setContent] = useState('');

  const [fileURL, setFileURL] = useState('');

  // Fetch file content on page load
  useEffect(() => {
    if (!file) {
      console.log('File is missing from the state, navigating back.');
      navigate('/');
      return;
    }

    const loadFile = async () => {
      file.fileName = file.fileName.replace(/\.\w+$/, ".pdf");
      const result = await fetchFileUrl(file);
      if (result.error) {
        console.error(result.error);
      } else {
        console.log('Result:', result);
        setFileURL(result);
      }
    };

    loadFile();

    const loadChatHistory = async () => {
      try {
        const response = await fetch(CONSTANTS['endpoint_fetch_chat'] + `?docId=${file.docId}&uid=${localStorage.getItem('userId')}`);
        if (response.ok) {
          const history = await response.json();
          if (history && history.messages) {
            setMessages(history.messages);
          }
        }
      } catch (error) {
        console.error("Error loading chat history:", error);
      }
    };

    if (file) {
      loadChatHistory();
    }
  }, [file, navigate]);

  useEffect(() => {
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }

    const saveChatHistory = async () => {
      try {
        const response = await fetch(CONSTANTS['endpoint_save_chat'], {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + localStorage.getItem('accessToken'),

          },
          body: JSON.stringify({
            docId: file.docId,
            uid: localStorage.getItem('userId'),
            messages: messages,
          }),
        });
        if (!response.ok) {
          console.error('Failed to save chat history:', response.status);
        }
      } catch (error) {
        console.error('Error saving chat history:', error);
      }
    };
    saveChatHistory();
  });

  const handleInputChange = (e) => setInputMessage(e.target.value);
  const handlePromptSelect = (prompt) => {
    setInputMessage(prompt.prompt);
    inputMessage = prompt.prompt;
    handleSubmit(prompt.value);
  };
  const handleClearMessages = () => {
    setMessages([]);
    searchPluginInstance.clearHighlights();
  };

  const handleSubmit = async (overwritePrompt) => {
    if (inputMessage.trim() === '' && typeof overwritePrompt !== 'string') return;
    searchPluginInstance.clearHighlights();
    setIsLoading(true);
    const newUserMessage = { text: inputMessage, isUser: true };
    const newBotMessage = { text: 'Thinking...', isUser: false };

    setMessages((prevMessages) => [
      ...prevMessages,
      newUserMessage,
      newBotMessage,
    ]);

    async function fetchChatbotResponse(inputMessage) {
      const accessToken = localStorage.getItem('accessToken');
      const userId = localStorage.getItem('userId');
      const mode = 'General';

      const requestBody = {
        query: modePrompts[mode] + inputMessage,
        uid: userId,
        docId: file.docId,
        isSourceNeeded: false,
      };

      setInputMessage('');

      const fetchOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
          // 'Access-Control-Allow-Origin': '*',
        },
        body: JSON.stringify(requestBody),
      };

      try {
        const response = await fetch(
          CONSTANTS['endpoint_get_preset_task'],
          fetchOptions,
        );
        if (!response.ok)
          throw new Error('Failed to fetch data from the server');
        return response.body.getReader();
      } catch (error) {
        console.error('Fetch error:', error);
        throw error;
      }
    }

    async function processStream(reader) {
      const decoder = new TextDecoder('utf-8');
      let completeMessage = '';

      async function read() {
        const { done, value } = await reader.read();
        if (done) return;
        const chunk = decoder.decode(value, { stream: true });
        console.log(chunk);
        completeMessage += chunk;

        await read();
      }

      await read();
      var fuzzyMatchedSources = [];
      // If completeMessage is a JSON object, then parse it
      if (completeMessage.startsWith("```json")) {
        completeMessage = completeMessage.trim().slice(7, -3).trim();
      }
      if (completeMessage.startsWith('{')) {
        const parsedMessage = JSON.parse(completeMessage);
        if (parsedMessage.response) {
          completeMessage = parsedMessage.response;
        }
        if (parsedMessage.sources) {
          // Filter sources using fuzzy match and store the matched strings
          fuzzyMatchedSources = parsedMessage.sources
            .map(source => {
              const fuzzyMatchResult = fuzzyMatch(source, content);
              return fuzzyMatchResult;
            })
            .filter(result => result !== null);
        }
      }
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        newMessages[newMessages.length - 1].text = completeMessage;
        newMessages[newMessages.length - 1].sources = fuzzyMatchedSources;
        return newMessages;
      });
      return completeMessage;
    }

    async function handleChatResponse(message) {
      try {
        const reader = await fetchChatbotResponse(message);
        await processStream(reader);
        setIsLoading(false);
      } catch (error) {
        console.error('Stream or fetch error:', error);
        setIsLoading(false);
      }
    }

    if (overwritePrompt !== undefined && typeof overwritePrompt === 'string') {
      handleChatResponse(overwritePrompt);
    } else {
      handleChatResponse(inputMessage);
    }
  };

  // const promptTemplates = [
  //   {
  //     id: 1,
  //     title: 'Content Summary',
  //     prompt: 'Can you summarize the main content or topic in this document?',
  //   },
  //   {
  //     id: 2,
  //     title: 'Key Takeaways',
  //     prompt:
  //       'What are the key takeaways or insights you gained from the information presented in this document?',
  //   },
  //   {
  //     id: 3,
  //     title: 'Specific Sections',
  //     prompt:
  //       'Is there a particular section or chapter in this document that you find interesting or challenging? Could you share more details about it?',
  //   },
  //   {
  //     id: 4,
  //     title: 'Implications',
  //     prompt:
  //       'Based on the content of this document, can you identify any real-world implications or potential applications? How might the information be applied in a practical context?',
  //   },
  // ];
  const promptTemplates = templates.General;

  const handleMouseMove = (e) => {
    const newLeftWidth = (e.clientX / window.innerWidth) * 100;
    if (newLeftWidth >= minLeftWidth && newLeftWidth <= 100 - minRightWidth) {
      setLeftWidth(newLeftWidth);
    }
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const renderClearMessagesButton = () => {
    if (!isLoading && hasMessages) {
      return (
        <Box className={styles.clearButtonContainer}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClearMessages}
            className={styles.clearButton}
          >
            Clear Messages
          </Button>
        </Box>
      );
    }
    return null;
  };

  const renderChatInput = () => (
    <Box className={styles.chatInputContainer}>
      <Box className={styles.inputFieldWrapper}>
        <TextField
          value={inputMessage}
          onChange={handleInputChange}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
            }
          }}
          variant="outlined"
          placeholder="Type a question or select a prompt."
          fullWidth
          size="medium"
          InputProps={{
            endAdornment: (
              <IconButton onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? <CircularProgress size={24} /> : <SendIcon />}
              </IconButton>
            ),
          }}
        />
      </Box>
    </Box>
  );

  const fileViewerProps = useMemo(
    () => ({
      selectedFileName: file?.fileName,
      fileURL: fileURL,
    }),
    [file?.fileName, fileURL],
  );

  const searchPluginInstance = searchPlugin();

  const transform = (slot) => ({
    ...slot,
    Download: () => <></>,
    DownloadMenuItem: () => <></>,
    EnterFullScreen: () => <></>,
    EnterFullScreenMenuItem: () => <></>,
    Open: () => <></>,
    Print: () => <></>,
  });

  const renderToolbar = (Toolbar) => (
    <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
  );

  const defaultLayoutPluginInstance = defaultLayoutPlugin(
    {
      sidebarTabs: (defaultTabs) => {
        return [defaultTabs[0]];
      },
      renderToolbar,
    }
  );
  const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

  return (
    <Box className={styles.pageContainer}>
      <Header pageTitle="Chat with RCA" showBackHome={true} />
      <Box className={styles.mainContent}>
        <Box
          className={styles.leftArea}
          style={{
            width: `${leftWidth}%`,
            minWidth: `${minLeftWidth}%`,
          }}
        >
          {file && (file.fileName.endsWith('.docx') || file.fileName.endsWith('.doc')) ? (
            <FileViewerMemoized {...fileViewerProps} />
          ) : (
            <Box style={{
              height: '92vh'
            }}>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                <Viewer
                  fileUrl={fileURL ? fileURL : "https://pdfobject.com/pdf/sample.pdf"}
                  httpHeaders={fileURL ? {
                    'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                  } : {}}
                  plugins={[searchPluginInstance, defaultLayoutPluginInstance]}
                  onDocumentLoad={async (e) => {
                    console.log('File URL:', fileURL);
                    const pageNumbers = [...Array(e.doc.numPages + 1).keys()].slice(1);
                    const pageContentPromises = pageNumbers.map(
                      async (pageNumber) => {
                        const page = await e.doc.getPage(pageNumber);
                        return page.getTextContent();
                      }
                    );

                    const pageContents = await Promise.all(pageContentPromises);
                    const contents = pageContents.map((content) => content.items.map((item) => item.str).join('')).join('');
                    setContent(contents);
                    content = contents;
                  }}
                />
              </Worker>
            </Box>
          )}
        </Box>
        <Box
          className={styles.gutterHorizontal}
          onMouseDown={handleMouseDown}
        />
        <Box
          className={styles.rightArea}
          style={{
            width: `${100 - leftWidth}%`,
            minWidth: `${minRightWidth}%`,
          }}
        >
          <Box className={styles.chatArea}>
            {hasMessages ? (
              <Box className={styles.messageArea}>
                <ul ref={messageListRef} className={styles.messageList}>
                  {messages.map((message, index) => (
                    <li
                      key={index}
                      className={`${styles.messageItem} ${message.isUser ? styles.messageUser : styles.messageBot}`}
                    >
                      {!message.isUser && (
                        <Avatar sx={{ bgcolor: 'lightgray', marginRight: 1.5 }}>
                          <ChatIcon />
                        </Avatar>
                      )}
                      {message.isUser ? (
                        message.text
                      ) : (
                        <Box className={styles.messageBot}>
                          <MarkdownText text={message.text} />
                          {message.sources && (
                            <Box className={styles.sourcesContainer}>
                              {message.sources.map((source, index) => (
                                <span key={index} style={{ margin: '2px' }}>
                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    className={styles.sourceButton}
                                    onClick={() => {
                                      console.log('Source String:', source);
                                      searchPluginInstance.highlight([source]);
                                    }}
                                  >
                                    Source {index + 1}
                                  </Button></span>
                              ))}
                            </Box>
                          )}
                        </Box>
                      )}
                    </li>
                  ))}
                  {renderClearMessagesButton()}
                </ul>
              </Box>
            ) : (
              <Box className={styles.promptArea}>
                <PromptSelection
                  prompts={promptTemplates}
                  onPromptSelect={handlePromptSelect}
                />
              </Box>
            )}
            {renderChatInput()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatPage;
