import CONSTANTS from '../config/CONSTANTS';

export function getUserRole() {
  const role = localStorage.getItem('userRole');
  console.log('getUserRole called', role);
  return role;
}

function getToken() {
  return localStorage.getItem('accessToken');
}

function getUid() {
  return localStorage.getItem('userId');
}

export async function getFileContentByDocId(endpoint, docId, fileType = 'pdf') {
  return await reqQueryfetchFileContent(
    endpoint,
    docId,
    fileType,
    'GET',
    // 'application/octet-stream',
    'text/plain',
  );
}

// Later change to get adminAccessibleFiles
export async function getUserFiles() {
  const fileListData = [];
  const endpoint = CONSTANTS['endpoint_get_all_files'];
  try {
    const response = await getFilesInfo(endpoint);
    if (response != null && response) {
      for (const item of response) {
        const fileName = item.docName;
        const docId = item.docId;
        const ownerId = item.ownerId;
        const createdDate = item.createdDate;
        const dateString = new Date(createdDate).toLocaleString('en-US', {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: true,
        });
        const status = item.status;
        fileListData.push({
          fileName,
          docId,
          ownerId,
          createdDate,
          dateString,
          status,
        });
      }
    }
  } catch (error) {
    console.error('Error fetching admin files:', error);
  }
  // Sort fileListData by createdDate in descending order
  fileListData.sort((a, b) => new Date(b.createdDate) - new Date(a.createdDate));
  return fileListData;
}

export async function getUserUploadedFiles() {
  const token = getToken();
  const fileListData = [];
  const endpoint = CONSTANTS['endpoint_get_all_files'];
  try {
    const response = await getFilesInfo(endpoint, token);
    if (response != null && response) {
      for (const item of response) {
        const fileName = item.docName;
        const docId = item._id;
        fileListData.push({
          fileName,
          docId,
        });
      }
    }
  } catch (error) {
    console.error('Error fetching user uploaded files:', error);
  }
  return fileListData;
}

export async function getFilesInfo(endpoint) {
  console.log('Getting files info...');
  const response = await reqQueryfetchText(endpoint, 'GET', 'application/json');
  return response;
}

export async function uploadFile(files, endpoint) {
  const formData = new FormData();
  let fileKeys = Object.keys(files);

  fileKeys.forEach(function (key) {
    const file = files[key];
    formData.append('files', file, file.name);
  });

  const response = await uploadFileFetchJson(endpoint, formData);
  if (response && response.body.docIds !== null) {
    alert('File Uploaded');
  } else {
    alert('File Upload Error');
  }
  return response;
}

export async function deleteFile(docId, endpoint) {
  const subsystem = 'MyLib';
  const path = `${subsystem}/${docId}`;
  try {
    const responseMessage = await reqPathDeletefetchText(
      endpoint,
      path,
      'DELETE',
      'application/json',
    );
    if (responseMessage.includes('Deletion Successful')) {
      return { success: true, message: 'Deletion successful' };
    } else {
      return { success: false, message: responseMessage };
    }
  } catch (error) {
    console.error('Error in sending data to server:', error.message);
    return { success: false, message: error.message };
  }
}

// export async function reqfetchReset(endpoint) {
//   try {
//     const queryParams = new URLSearchParams({
//       subSystem: 'MyLib',
//     });
//     const url = `${endpoint}/${queryParams.toString()}`;
//     const options = {
//       method: 'POST',
//       // mode: 'no-cors'
//     };
//     const response = await fetch(url, options);
//     return response;
//   } catch (e) {
//     console.log(`Error in sending data to server: ${e.message}`);
//   }
// }

export async function reqQueryfetchFileContent(
  endpoint,
  query,
  fileType,
  method,
  contentType,
) {
  const accessToken = getToken();
  const userId = getUid();
  try {
    const queryParams = new URLSearchParams({
      subSystem: 'MyLib',
      docId: query,
      fileType: fileType,
      uid: userId
    });
    const url = `${endpoint}?${queryParams.toString()}`;
    const response = await fetch(url, {
      method: method,
      // headers: { 'Content-Type': contentType },
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${accessToken}`,
        // 'User-Agent':
        //   'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.3',
      },
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error(`HTTP error, status = ${response.status}, ${errorText}`);
      throw new Error(`HTTP error, status = ${response.status}`);
    }

    // console.log('response', response);
    return await response;
  } catch (e) {
    console.error(`Error in sending data to server: ${e.message}`);
    throw e;
  }
}

export async function reqQueryfetchText(endpoint, method, contentType) {
  const accessToken = getToken();
  const userId = getUid();
  try {
    const queryParams = new URLSearchParams({
      subSystem: 'MyLib',
      uid: userId
    });
    const url = `${endpoint}?${queryParams.toString()}`;
    const options = {
      // mode: 'no-cors',
      method: method,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${accessToken}`,
      },
    };
    const response = await fetch(url, options);
    const result = await response.json();
    console.log('Result');
    console.log(JSON.stringify(result));
    if (result.errors) {
      const error = result.errors[0];
      alert(result.errors[0]);
      if (error.extensions.code === 'BAD_USER_INPUT') {
        alert(`${error.message}`);
      } else {
        alert(`${error.extensions.code}: ${error.message}`);
      }
    }
    return result;
  } catch (e) {
    console.log(`Error in sending data to server: ${e.message}`);
  }
}

export async function reqPathDeletefetchText(
  endpoint,
  path,
  method,
  contentType,
) {
  const accessToken = getToken();
  const url = `${endpoint}/${path}`;

  try {
    const response = await fetch(url, {
      method: method,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.ok) {
      return 'Deletion Successful';
    } else {
      const errorText = await response.text();
      throw new Error(`Failed to delete: ${errorText}`);
    }
  } catch (error) {
    console.error('Error in sending data to server:', error.message);
    return error.message;
  }
}

// export async function reqPathfetchText(endpoint, path, method, contentType) {
//   try {
//     const url = `${endpoint}/${path}`;
//     console.log('url: ', url);
//     const response = await fetch(url, {
//       // mode: 'no-cors',
//       method: method,
//       headers: { 'Content-Type': contentType },
//     });
//     const body = await response.text();
//     const result = JSON.parse(body);
//     if (result.errors) {
//       const error = result.errors[0];
//       alert(result.errors[0]);
//       if (error.extensions.code === 'BAD_USER_INPUT') {
//         alert(`${error.message}`);
//       } else {
//         alert(`${error.extensions.code}: ${error.message}`);
//       }
//     }
//     return result;
//   } catch (e) {
//     console.log(`Error in sending data to server: ${e.message}`);
//   }
// }

export async function fetchText(endpoint, reqBody, path, method, contentType) {
  try {
    const url = path ? `${endpoint}/${path}` : endpoint;
    const response = await fetch(url, {
      // mode: 'no-cors',
      method: method,
      headers: {
        'Content-Type': contentType,
        'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(reqBody),
    });
    const body = await response.text();
    const result = JSON.parse(body);
    if (result.errors) {
      const error = result.errors[0];
      alert(result.errors[0]);
      if (error.extensions.code === 'BAD_USER_INPUT') {
        alert(`${error.message}`);
      } else {
        alert(`${error.extensions.code}: ${error.message}`);
      }
    }
    return result;
  } catch (e) {
    console.log(`Error in sending data to server: ${e.message}`);
  }
}

export async function uploadFileFetchJson(endpoint, formData) {
  try {
    const accessToken = getToken();
    const response = await fetch(endpoint, {
      method: 'POST',
      body: formData,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      timeout: 600
    });
    return response;
  } catch (e) {
    console.log(`Error in sending data to server: ${e.message}`);
  }
}

export async function fetchChatData(
  setter,
  query,
  endpoint,
  type,
  accessToken,
) {
  try {
    setter(type === 1 ? [] : '');
    const reqBody = {
      query: query,
    };
    const userId = getUid();
    const queryParams = new URLSearchParams({
      subSystem: 'MyLib',
      uid: userId
    });
    const url = `${endpoint}?${queryParams.toString()}`;

    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
        // 'Access-Control-Allow-Origin': '*',
      },
      body: JSON.stringify(reqBody),
    };

    fetch(url, options)
      .then((response) => {
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let accumulatedText = '';
        let lastProcessedIndex = 0;

        function read() {
          return reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                if (type === 1) {
                  processText(
                    accumulatedText.substring(lastProcessedIndex),
                    setter,
                  );
                } else {
                  setter(accumulatedText);
                }
                return;
              }
              accumulatedText += decoder.decode(value, { stream: true });
              if (type === 1 && accumulatedText.includes('\n')) {
                const lastNewLineIndex = accumulatedText.lastIndexOf('\n');
                const newText = accumulatedText.substring(
                  lastProcessedIndex,
                  lastNewLineIndex,
                );
                lastProcessedIndex = lastNewLineIndex;
                processText(newText, setter);
              } else if (type !== 1) {
                setter(accumulatedText);
              }
              return read();
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }
        return read();
      })
      .catch((error) => console.error('Error:', error));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
}

function processText(text, setter) {
  extractQuestions(text).then((questions) => {
    setter((prev) => [...new Set([...prev, ...questions])]); // Avoid duplicates
  });
}

export async function extractQuestions(rawQuestions) {
  const lines = rawQuestions.split('\n');
  const questions = lines
    .map((line) => {
      const match = line.match(/^\d+\.\s*(.*)/);
      if (match) {
        return match[1];
      }
      return null;
    })
    .filter((question) => question !== null);
  return questions;
}
