import React, { useState } from 'react';
import {
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import PictureAsPdfOutlinedIcon from '@mui/icons-material/PictureAsPdfOutlined';
import styles from '../assets/css/components/FileTable.module.css';

const FileTable = ({
  files,
  setFileListData,
  selectedFile,
  handleSelectFile,
  handleDownloadFile,
  handleDeleteFile,
  comparedFiles = [],
  setComparedFiles,
  isAdmin = false,
}) => {
  const [sortField, setSortField] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  const getFileIcon = (fileName) => {
    if (fileName.toLowerCase().endsWith('.pdf')) {
      return <PictureAsPdfOutlinedIcon />;
    } else {
      return <InsertDriveFileOutlinedIcon />;
    }
  };

  const formatDate = (createdDate) => {
    if (!createdDate) return 'unknown';
    const date = new Date(createdDate * 1000);
    return new Intl.DateTimeFormat(navigator.language, {
      month: 'short',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(date);
  };

  const toggleSort = (field) => {
    const isAsc = sortField === field && sortOrder === 'asc';
    setSortField(field);
    setSortOrder(isAsc ? 'desc' : 'asc');
    setFileListData((prevData) =>
      sortFiles(prevData, field, isAsc ? 'desc' : 'asc'),
    );
  };

  const sortFiles = (files, field, order) => {
    const sortedFiles = [...files];

    sortedFiles.sort((a, b) => {
      const aValue = a[field];
      const bValue = b[field];

      if (aValue === undefined || aValue === null) return 1;
      if (bValue === undefined || bValue === null) return -1;

      if (field === 'createdDate') {
        const dateA = new Date(aValue);
        const dateB = new Date(bValue);
        return order === 'asc' ? dateA - dateB : dateB - dateA;
      }

      return order === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    });

    return sortedFiles;
  };

  const handleToggleFile = (file) => {
    const currentIndex = comparedFiles.findIndex((f) => f.docId === file.docId);
    let newChecked = [...comparedFiles];

    if (currentIndex === -1) {
      if (newChecked.length < 2) {
        newChecked.push(file);
      } else {
        newChecked[0] = newChecked[1];
        newChecked[1] = file;
      }
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setComparedFiles(newChecked);
  };

  return (
    <TableContainer component={Paper} className={styles.tableContainer}>
      <Table>
        <TableHead>
          <TableRow>
            {isAdmin && <TableCell className={styles.cellCheckBox}></TableCell>}
            <TableCell className={styles.cellFileIcon}></TableCell>
            <TableCell className={styles.cellFileName}>
              <TableSortLabel
                active={sortField === 'fileName'}
                direction={sortOrder}
                onClick={() => toggleSort('fileName')}
              >
                File Name
              </TableSortLabel>
            </TableCell>
            {/* <TableCell>
              <TableSortLabel
                active={sortField === 'uploader'}
                direction={sortOrder}
                onClick={() => toggleSort('uploader')}
              >
                Uploader
              </TableSortLabel>
            </TableCell> */}
            <TableCell>
              <TableSortLabel
                active={sortField === 'status'}
                direction={sortOrder}
                onClick={() => toggleSort('status')}
              >
                Status
              </TableSortLabel>
            </TableCell>
            <TableCell>
              <TableSortLabel
                active={sortField === 'createdDate'}
                direction={sortOrder}
                onClick={() => toggleSort('createdDate')}
              >
                Date
              </TableSortLabel>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {files.map((file, index) => (
            <TableRow
              key={file.docId || index}
              onClick={() => handleSelectFile(file)}
              className={
                file === selectedFile ? styles.selectedRow : styles.tableRow
              }
            >
              {isAdmin && (
                <TableCell className={styles.cellCheckBox}>
                  <Checkbox
                    checked={comparedFiles.some((f) => f.docId === file.docId)}
                    onChange={() => handleToggleFile(file)}
                  />
                </TableCell>
              )}
              <TableCell className={styles.cellFileIcon}>
                {getFileIcon(file.fileName)}
              </TableCell>
              <TableCell className={styles.cellFileName}>
                {file.fileName}
              </TableCell>
              {/* <TableCell>{file.uploader || 'unknown'}</TableCell> */}
              <TableCell>{file.status}</TableCell>
              <TableCell>{formatDate(file.createdDate)}</TableCell>
              <TableCell>
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDownloadFile(file.docId, file.ownerId, file.fileName, e);
                  }}
                >
                  <CloudDownloadOutlinedIcon />
                </IconButton>
                <IconButton
                  className={styles.deleteBtn}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFile(file.docId, e);
                  }}
                >
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FileTable;
