export const PAGES = {
  admin: '/admin',
  board: '/board',
  compare: '/compare-files',
  analysis: '/analysis',
  customtag: '/customTag',
  chat: '/chat',
  fill: '/fill',
  login: 'login',
  profile: '/profile',
  'idoc-rca-form': '/rca-form',
};
