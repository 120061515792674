import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
    Box,
    Typography,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    IconButton,
    CircularProgress,
    Avatar,
} from '@mui/material';
import Header from '../components/Header';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from '../assets/css/pages/CustomTagCreatorPage.module.css';
import FileViewer from '../components/FileViewer';
import CONSTANTS from '../config/CONSTANTS';
import { fetchFileUrl } from '../utils/ViewFileHelper';
import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { searchPlugin } from '@react-pdf-viewer/search';
import '@react-pdf-viewer/search/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import ChatIcon from '@mui/icons-material/Chat';
import SendIcon from '@mui/icons-material/Send';

const FileViewerMemoized = React.memo(FileViewer);

export function fuzzyMatch(target, content) {
    // Remove all non-alphabetic characters from target
    const sanitizedTarget = target.replace(/[^a-zA-Z]/g, "");

    // Create a RegExp pattern that ignores spaces and other non-alphabetic characters in content
    const pattern = new RegExp(sanitizedTarget.split("").join("[^a-zA-Z]*"), "i");

    // Perform the match
    const match = String(content).match(pattern);

    return match ? match[0] : null; // use the result in your CMD + F
}

const CustomTagCreatorPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const file = location.state?.doc;
    const [fileURL, setFileURL] = useState('');
    const [selectedTag, setSelectedTag] = useState('Create new');
    const [tag, setTag] = useState('');
    const [extractionQuery, setExtractionQuery] = useState('');
    const [value, setValue] = useState('');
    const [tagList, setTagList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isQueryLoading, setIsQueryLoading] = useState(false);
    const [tagError, setTagError] = useState(false);
    const [content, setContent] = useState('');
    const [leftWidth, setLeftWidth] = useState(55); // Initialize left area width percentage
    const minLeftWidth = 30; // Minimum width percentage for the left area
    const minRightWidth = 30; // Minimum width percentage for the right area
    const messageListRef = useRef(null);

    const searchPluginInstance = searchPlugin();

    const transform = (slot) => ({
        ...slot,
        Download: () => <></>,
        DownloadMenuItem: () => <></>,
        EnterFullScreen: () => <></>,
        EnterFullScreenMenuItem: () => <></>,
        Open: () => <></>,
        Print: () => <></>,
    });

    const renderToolbar = (Toolbar) => (
        <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
    );

    const defaultLayoutPluginInstance = defaultLayoutPlugin(
        {
            sidebarTabs: (defaultTabs) => {
                return [defaultTabs[0]];
            },
            renderToolbar,
        }
    );
    const { renderDefaultToolbar } = defaultLayoutPluginInstance.toolbarPluginInstance;

    const handleMouseMove = (e) => {
        const newLeftWidth = (e.clientX / window.innerWidth) * 100;
        if (newLeftWidth >= minLeftWidth && newLeftWidth <= 100 - minRightWidth) {
            setLeftWidth(newLeftWidth);
        }
    };

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    useEffect(() => {
        if (!file) {
            console.log('File is missing from the state, navigating back.');
            navigate('/');
            return;
        }

        const loadFile = async () => {
            file.fileName = file.fileName.replace(/\.\w+$/, ".pdf");
            const result = await fetchFileUrl(file);
            if (result.error) {
                console.error(result.error);
            } else {
                setFileURL(result);
            }
        };

        loadFile();
        fetchTagList();
    }, [file, navigate]);

    const fetchTagList = async () => {
        try {
            const response = await fetch(`${CONSTANTS['query_tag_list']}?uid=${localStorage.getItem('userId')}&docId=${file.docId}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setTagList(data.tags);
        } catch (error) {
            console.error('Error fetching tag list:', error);
        }
    };

    const handleTagSelect = (event) => {
        const selectedValue = event.target.value;
        setSelectedTag(selectedValue);
        if (selectedValue === 'Create new') {
            setTag('');
            setExtractionQuery('');
            setValue('');
        } else {
            const selectedTagData = tagList.find(tagData => tagData.tag === selectedValue);
            if (selectedTagData) {
                setTag(selectedTagData.tag);
                setExtractionQuery(selectedTagData.query);
                setValue(selectedTagData.value);
            }
        }
    };

    const handleRunQuery = async () => {
        setIsQueryLoading(true);
        try {
            const response = await fetch(CONSTANTS['query_custom_tag'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    uid: localStorage.getItem('userId'),
                    docId: file.docId,
                    query: extractionQuery,
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.text();
            if (result.startsWith("```json")) {
                result = result.trim().slice(7, -3).trim();
            }
            if (result.startsWith('{')) {
                const parsedMessage = JSON.parse(result);
                if (parsedMessage.response) {
                    setValue(parsedMessage.response);
                    value = parsedMessage.response;
                }
            }
        } catch (error) {
            console.error('Error running query:', error);
            setValue('Error running query');
        } finally {
            setIsQueryLoading(false);
        }
    };

    const handleSaveTag = async () => {
        setIsLoading(true);
        setTagError(false);
        try {
            const response = await fetch(CONSTANTS['save_custom_tag'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    uid: localStorage.getItem('userId'),
                    docId: file.docId,
                    tag: tag,
                    query: extractionQuery,
                    value: value,
                }),
            });
            if (!response.ok) {
                if (response.status === 409) {
                    setTagError(true);
                } else {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } else {
                fetchTagList();
                setSelectedTag(tag);
            }
        } catch (error) {
            console.error('Error saving tag:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleDeleteTag = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(CONSTANTS['delete_custom_tag'], {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                },
                body: JSON.stringify({
                    uid: localStorage.getItem('userId'),
                    docId: file.docId,
                    tag: tag,
                }),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            fetchTagList();
            setSelectedTag('Create new');
            setTag('');
            setExtractionQuery('');
            setValue('');
        } catch (error) {
            console.error('Error deleting tag:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const fileViewerProps = useMemo(() => ({
        selectedFileName: file?.fileName,
        fileURL: fileURL,
    }), [file?.fileName, fileURL]);

    return (
        <Box className={styles.pageContainer}>
            <Header pageTitle="Custom Tag Creator" showBackHome={true} />
            <Box className={styles.mainContent}>
                <Box
                    className={styles.leftArea}
                    style={{
                        width: `${leftWidth}%`,
                        minWidth: `${minLeftWidth}%`,
                    }}
                >
                    {file && (file.fileName.endsWith('.docx') || file.fileName.endsWith('.doc')) ? (
                        <FileViewerMemoized {...fileViewerProps} />
                    ) : (
                        <Box style={{
                            height: '92vh'
                        }}>
                            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.js">
                                <Viewer
                                    fileUrl={fileURL ? fileURL : "https://pdfobject.com/pdf/sample.pdf"}
                                    httpHeaders={fileURL ? {
                                        'Authorization': 'Bearer ' + localStorage.getItem('accessToken'),
                                    } : {}}
                                    plugins={[searchPluginInstance, defaultLayoutPluginInstance]}
                                    onDocumentLoad={async (e) => {
                                        console.log('File URL:', fileURL);
                                        const pageNumbers = [...Array(e.doc.numPages + 1).keys()].slice(1);
                                        const pageContentPromises = pageNumbers.map(
                                            async (pageNumber) => {
                                                const page = await e.doc.getPage(pageNumber);
                                                return page.getTextContent();
                                            }
                                        );

                                        const pageContents = await Promise.all(pageContentPromises);
                                        const contents = pageContents.map((content) => content.items.map((item) => item.str).join('')).join('');
                                        setContent(contents);
                                    }}
                                />
                            </Worker>
                        </Box>
                    )}
                </Box>
                <Box
                    className={styles.gutterHorizontal}
                    onMouseDown={handleMouseDown}
                />
                <Box
                    className={styles.rightArea}
                    style={{
                        width: `${100 - leftWidth}%`,
                        minWidth: `${minRightWidth}%`,
                    }}
                >
                    <Box className={styles.formContainer}>
                        <FormControl fullWidth margin="normal">
                            <InputLabel id="tag-select-label">Select Tag</InputLabel>
                            <Select
                                labelId="tag-select-label"
                                id="tag-select"
                                value={selectedTag}
                                label="Select Tag"
                                onChange={handleTagSelect}
                            >
                                <MenuItem value="Create new">Create new</MenuItem>
                                {tagList.map((tagData) => (
                                    <MenuItem key={tagData.tag} value={tagData.tag}>{tagData.tag}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            label="Tag"
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                            fullWidth
                            margin="normal"
                            error={tagError}
                            helperText={tagError ? "Tag already exists" : ""}
                        />
                        <TextField
                            label="Extraction Query"
                            value={extractionQuery}
                            onChange={(e) => setExtractionQuery(e.target.value)}
                            fullWidth
                            margin="normal"
                            multiline
                        />
                        <Button
                            variant="contained"
                            onClick={handleRunQuery}
                            disabled={isQueryLoading}
                            style={{ margin: '8px 0' }}
                        >
                            {isQueryLoading ? <CircularProgress size={24} /> : 'Run Query'}
                        </Button>
                        <TextField
                            label="Value"
                            value={value}
                            onChange={(e) => setValue(e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleSaveTag}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} /> : 'Save'}
                            </Button>
                            {selectedTag !== 'Create new' && (
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={handleDeleteTag}
                                    disabled={isLoading}
                                >
                                    {isLoading ? <CircularProgress size={24} /> : 'Delete'}
                                </Button>
                            )}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default CustomTagCreatorPage;