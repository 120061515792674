import InfoIcon from '@mui/icons-material/Info';
// import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../assets/css/components/DashboardSideBar.module.css';

function DashboardSideBar({ selectedFile, comparedFiles }) {
  const lowerBtnStyle = {
    justifyContent: 'flex-start',
    paddingLeft: '25px',
    width: '100%',
    boxSizing: 'border-box',
  };

  const navigate = useNavigate();

  const handleCompare = () => {
    if (comparedFiles[0].status !== "ready" || comparedFiles[1].status !== "ready") {
      alert("Please wait for processing to complete on selected files");
      return;
    }
    if (comparedFiles.length === 2) {
      if (!comparedFiles[0].fileName.endsWith('.docx') || !comparedFiles[1].fileName.endsWith('.docx')) {
        alert('Only .DOCX format is supported for risk analysis as of now');
        return;
      }
      navigate('/compare-files', {
        state: {
          doc1: comparedFiles[0],
          doc2: comparedFiles[1],
        },
      });
    } else {
      alert('Please select two DOCX files to compare and analyze');
    }
  };

  // const handleCompare2 = () => {
  //   if (comparedFiles.length === 2) {
  //     navigate('/compare-files', {
  //       state: {
  //         doc1: comparedFiles[0],
  //         doc2: comparedFiles[1],
  //       },
  //     });
  //   } else {
  //     alert('Please select exactly two files to compare.');
  //   }
  // };

  const handleChat = () => {
    if (selectedFile.status !== "ready") {
      alert("Please wait for processing to complete");
      return;
    }
    navigate('/chat', {
      state: {
        file: selectedFile,
      },
    });
  };

  const handleFill = () => {
    if (selectedFile.status !== "ready") {
      alert("Please wait for processing to complete");
      return;
    }
    navigate('/fill', {
      state: {
        file: selectedFile,
      },
    });
  };

  const handleRiskAnalysis = () => {
    if (selectedFile.status !== "ready") {
      alert("Please wait for processing to complete");
      return;
    }
    navigate('/analysis', {
      state: {
        doc: selectedFile,
      },
    });
  };

  const handleCustomTagCreator = () => {
    if (selectedFile.status !== "ready") {
      alert("Please wait for processing to complete");
      return;
    }
    navigate('/customTag', {
      state: {
        doc: selectedFile,
      },
    });
  };

  return (
    <Box className={styles.sidebar}>
      <Box className={styles.sidebarContent}>
        <Box className={styles.upperButtonGroup}>
          <Button
            className={styles.toolButton}
            variant="outlined"
            disabled={selectedFile === null}
            onClick={handleChat}
          >
            Chat
          </Button>
          <Button
            className={styles.toolButton}
            variant="outlined"
            disabled={selectedFile === null}
            onClick={handleFill}
          >
            Auto Fill
          </Button>
          <Button
            className={styles.toolButton}
            variant="outlined"
            onClick={handleRiskAnalysis}
            disabled={selectedFile === null}
          >
            Risk Analysis
          </Button>
          <Button
            className={styles.toolButton}
            variant="outlined"
            onClick={handleCompare}
            disabled={comparedFiles.length !== 2}
          >
            Compare Drafts
          </Button>
          {/* <Button
            className={styles.toolButton}
            variant="outlined"
            onClick={handleCustomTagCreator}
            disabled={selectedFile === null}
          >
            Custom Tag Creator
          </Button> */}
          {/* <Button
            className={styles.toolButton}
            variant="outlined"
            onClick={handleCompare2}
            disabled={comparedFiles.length !== 2}
          >
            Compare
          </Button> */}
        </Box>
        <Box className={styles.flexSpacer}></Box>
        <Box className={styles.lowerButtonGroup}>
          {/* <Button
            className={styles.setButton}
            startIcon={<SettingsIcon />}
            onClick={() => {}}
            sx={lowerBtnStyle}
          >
            Settings
          </Button> */}
          {/* <Button
            className={styles.setButton}
            startIcon={<Brightness4Icon />}
            onClick={() => {}}
            sx={lowerBtnStyle}
          >
            Toggle Theme
          </Button> */}
          <Button
            className={styles.setButton}
            startIcon={<InfoIcon />}
            onClick={() => { alert("RCA Manager Admin Dashboard"); }}
            sx={lowerBtnStyle}
          >
            About
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default DashboardSideBar;
