import { Box, Button, TextField } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../assets/css/pages/AdminDashboardPage.module.css';
import DashboardSideBar from '../components/DashboardSideBar';
import FileTable from '../components/FileTable';
import Header from '../components/Header';
import Loading from '../components/Loading';
import CONSTANTS from '../config/CONSTANTS';
import { deleteFile, getUserFiles, uploadFile } from '../utils/Helperfunction';

function AdminDashboardPage() {
  const [fileListData, setFileListData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [comparedFiles, setComparedFiles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const filteredFiles = fileListData.filter((file) =>
    file.fileName.toLowerCase().includes(searchQuery.toLowerCase()),
  );

  useEffect(() => {
    async function fetchFiles() {
      setLoading(true);
      try {
        const files = await getUserFiles();
        setFileListData(files);
      } catch (error) {
        console.error('Failed to fetch files:', error);
      }
      setLoading(false);
    }
    fetchFiles();

    const intervalId = setInterval(fetchFiles, 15000); // Fetch files every 15 seconds
    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (event.target.closest('.MuiBottomNavigation-root')) {
        return;
      }
      if (
        !event.target.closest(`.${styles.fileItem}`) &&
        !event.target.closest('button')
      ) {
        setSelectedFile(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleSelectFile = (file) => {
    setSelectedFile(file);
  };

  const handleFileUpload = async (event) => {
    const files = event.target.files;
    if (files.length > 0) {
      setLoading(true);
      try {
        const endpoint = CONSTANTS['endpoint_upload_file'];
        await uploadFile(files, endpoint);
        const updatedFiles = await getUserFiles();
        setFileListData(updatedFiles);
      } catch (error) {
        console.error('Error uploading file:', error);
      }
      setLoading(false);
    }
  };

  const handleDownloadFile = async (docId, uid, fileName) => {
    const fileType = fileName.split('.').pop();
    const endpoint = CONSTANTS['ENDPOINT_GET_FILE'];

    try {
      // Construct the full URL to the file. You might need to adjust how the URL is formed based on your server setup.
      const fileURL = `${endpoint}?docId=${docId}&uid=${uid}&fileType=${fileType}`;

      // Fetch the file as a blob from the server
      const response = await fetch(fileURL, { method: 'GET' });
      if (!response.ok) {
        throw new Error(
          `Failed to fetch file: ${response.statusText} (status: ${response.status})`,
        );
      }

      // Get the blob from the response
      const blob = await response.blob();

      // Create a blob URL for the blob object
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element to facilitate downloading
      const link = document.createElement('a');
      link.href = url;
      link.download = fileName; // Set the download attribute to specify the filename

      // Append the link to the document, trigger the download, and then remove the link
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      // Clean up by revoking the blob URL to release memory
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      alert(
        'An error occurred while downloading the file. Please check your connection and try again.',
      );
    }
  };

  const handleDeleteFile = async (docId, e) => {
    e.stopPropagation();
    setLoading(true);
    try {
      const endpoint = CONSTANTS['endpoint_delete_file'];
      await deleteFile(docId, endpoint);
      const updatedFiles = await getUserFiles();
      setFileListData(updatedFiles);
    } catch (error) {
      console.error('Error deleting file:', error);
    }
    setLoading(false);
  };

  return (
    <Box className={styles.dashboardPage}>
      <Header pageTitle="RCA Manager Admin Dashboard" />
      <Box className={styles.contentWrapper}>
        <DashboardSideBar
          selectedFile={selectedFile}
          comparedFiles={comparedFiles}
        />
        <Box component="main" className={styles.mainContent}>
          {loading && <Loading />}
          <Box className={styles.uploadBtnWrapper}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => fileInputRef.current.click()}
              fullWidth
            >
              Upload File
            </Button>
            <input
              ref={fileInputRef}
              type="file"
              hidden
              onChange={handleFileUpload}
            />
          </Box>
          <Box className={styles.searchFieldWrapper}>
            <TextField
              fullWidth
              label="Search file"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
          <Box className={styles.fileTableWrapper}>
            <FileTable
              files={filteredFiles}
              selectedFile={selectedFile}
              setFileListData={setFileListData}
              handleSelectFile={handleSelectFile}
              handleDownloadFile={handleDownloadFile}
              handleDeleteFile={handleDeleteFile}
              comparedFiles={comparedFiles}
              setComparedFiles={setComparedFiles}
              isAdmin={true}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AdminDashboardPage;
